/* =======================================================================================================
  File Name: moduleFormationAction.js
  Description: Formation Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  filterFormations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/formations/filter", { payload })
        .then((response) => {
          commit("SET_FORMATIONS", response.data.formations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPostedFormations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/formations/posted")
        .then((response) => {
          commit("SET_FORMATIONS", response.data.formations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLast9PostedFormations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/last9/postedhome")
        .then((response) => {
          commit("SET_FORMATIONS", response.data.formations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFormationsOnEpavillon({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/formations/epavillon")
        .then((response) => {
          commit("SET_FORMATIONS_EPAVILLON", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFormations({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/formations/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_FORMATIONS", response.data.formations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUnValideFormations({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/formations/un-validate/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_FORMATIONS", response.data.formations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  valideFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/valide-formation", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unValideFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/un-valide-formation", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFormationsByUserId({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { page, limit, userId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/formations/by-user/${userId}/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_FORMATIONS", response.data.formations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFormationById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/formations/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFormationBySlug(context, slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/formations/bySlug/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/formations", { payload }, config)
        .then((response) => {
          commit("ADD_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerUserOnFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/register-user", { payload }, config)
        .then((response) => {
          console.log(response.data);
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  postFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/post", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unPostFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/un-post", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDocFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/delete-file", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addApercuVideoFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    // const uniquePrefix = Date.now() + "-" + Math.round(Math.random() * 1e9);
    // const video_name = uniquePrefix + payload.video_apercu.originalname;
    formData.append("id", payload.id);
    formData.append("video_apercu", payload.video_apercu);
    // formData.append("video_name", video_name);
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formations/upload-apercu-video", formData, config)
        .then((response) => {
          commit("UPDATE_FORMATION", response.data.formation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteFormation({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/formations/${id}`)
        .then((response) => {
          commit("DELETE_FORMATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTypeFormations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/types-formation")
        .then((response) => {
          commit("SET_TYPE_FORMATIONS", response.data.types);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTypeFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/types-formation", { payload }, config)
        .then((response) => {
          commit("ADD_TYPE_FORMATION", response.data.type);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTypeFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/types-formation", { payload }, config)
        .then((response) => {
          commit("UPDATE_TYPE_FORMATION", response.data.type);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTypeFormation({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/types-formation/delete", { payload }, config)
        .then((response) => {
          commit("DELETE_TYPE_FORMATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCategorieFormations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/formation-categories")
        .then((response) => {
          commit("SET_CATEGORIE_FORMATIONS", response.data.categories);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCategorieFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/formation-categories", { payload }, config)
        .then((response) => {
          commit("ADD_CATEGORIE_FORMATION", response.data.categorie);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCategorieFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formation-categories", { payload }, config)
        .then((response) => {
          commit("UPDATE_CATEGORIE_FORMATION", response.data.categorie);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  softdeleteCategorieFormation({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/formation-categories/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_CATEGORIE_FORMATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getObjectifFormations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/objectif-formations")
        .then((response) => {
          commit("SET_OBJECTIF_FORMATIONS", response.data.objectifs);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createObjectifFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/objectif-formations", { payload }, config)
        .then((response) => {
          commit("ADD_OBJECTIF_FORMATION", response.data.objectif);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateObjectifFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/objectif-formations", { payload }, config)
        .then((response) => {
          commit("UPDATE_OBJECTIF_FORMATION", response.data.objectif);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  softdeleteObjectifFormation({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/objectif-formations/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_OBJECTIF_FORMATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCibleFormations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/cible-formations")
        .then((response) => {
          commit("SET_CIBLE_FORMATIONS", response.data.cibles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCibleFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/cible-formations", { payload }, config)
        .then((response) => {
          commit("ADD_CIBLE_FORMATION", response.data.cible);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCibleFormation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/cible-formations", { payload }, config)
        .then((response) => {
          commit("UPDATE_CIBLE_FORMATION", response.data.cible);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  softdeleteCibleFormation({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/cible-formations/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_CIBLE_FORMATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getApprentissageByUserId({ commit }, userId) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/apprentissage/${userId}`, config)
        .then((response) => {
          commit("SET_APPRENTISSAGE", response.data.apprentissages);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getApprentissageSigleByUserIdAndFormationId({ commit }, payload) {
    const { userId, formationId } = payload;
    //console.log(payload);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/apprentissage/${userId}/${formationId}`, config)
        .then((response) => {
          commit("CURRENT_APPRENTISAGE", response.data.apprentissage);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createApprentissage({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/apprentissage", { payload }, config)
        .then((response) => {
          commit("ADD_APPRENTISSAGE", response.data.apprentissage);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateApprentissage({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/apprentissage", { payload }, config)
        .then((response) => {
          commit("UPDATE_APPRENTISSAGE", response.data.apprentissage);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  archiveApprentissage({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/apprentissage/archive", { payload }, config)
        .then((response) => {
          commit("DELETE_APPRENTISSAGE", response.data.apprentissage._id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  TerminerModuleApprentissage({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/apprentissage/terminer-module", { payload }, config)
        .then((response) => {
          commit("UPDATE_APPRENTISSAGE", response.data.apprentissage);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
