/* =======================================================================================================
  File Name: moduleSearch.js
  Description: Search Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */
import state from "./moduleSearchState.js";
import mutations from "./moduleSearchMutations.js";
import actions from "./moduleSearchActions.js";
import getters from "./moduleSearchGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
