/* =======================================================================================================
  File Name: modulePaysAction.js
  Description: Pays Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  searchPays({ commit }, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pays/search/countries/${query}`)
        .then((response) => {
          commit("SET_PAYS_RESULT", response.data.pays);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPays({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pays/${page}/${limit}`)
        .then((response) => {
          commit("SET_PAYS", response.data.pays);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPaysById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pays/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPaysByFlag(context, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pays/${code}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPays({ commit }, payload) {
    console.log(payload);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/pays", { payload }, config)
        .then((response) => {
          commit("ADD_PAYS", response.data.pays);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addPaysBanner({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/pays/banner", { payload }, config)
        .then((response) => {
          commit("UPDATE_PAYS", response.data.pays);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePays({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/pays", { payload }, config)
        .then((response) => {
          commit("UPDATE_PAYS", response.data.pays);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePays({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/pays/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_PAYS", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deteleCountryFlag({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/pays/delete-flag", { payload })
        .then((response) => {
          commit("UPDATE_PAYS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchRegion(context, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/regions/search/${query}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRegion({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/regions")
        .then((response) => {
          commit("SET_REGION", response.data.regions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createRegion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/regions", { payload }, config)
        .then((response) => {
          commit("ADD_REGION", response.data.region);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRegion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/regions", { payload }, config)
        .then((response) => {
          commit("UPDATE_REGION", response.data.region);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteRegion({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/regions/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_REGION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
