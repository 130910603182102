export default {
  getFormationById: (state) => (id) => {
    return state.formations.length > 0
      ? state.formations.find((f) => f._id === id)
      : null;
  },
  getFormationBySlug: (state) => (slug) => {
    return state.formations.length > 0
      ? state.formations.find((f) => f.slug === slug)
      : null;
  },
  getApercuVideoById: (state) => (id) => {
    const formation =
      state.formations.length > 0
        ? state.formations.find((f) => f._id === id)
        : null;

    return formation ? formation.video_apercu : null;
  },
};
