/* =======================================================================================================
  File Name: moduleDocumentAction.js
  Description: Document Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  getTypeDocuments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/types-document")
        .then((response) => {
          commit("SET_TYPES_DOCUMENT", response.data.types);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTypeDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/types-document", { payload }, config)
        .then((response) => {
          commit("ADD_TYPE_DOCUMENT", response.data.type);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTypeDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/types-document", { payload }, config)
        .then((response) => {
          commit("UPDATE_TYPE_DOCUMENT", response.data.type);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTypeDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/types-document/delete", { payload }, config)
        .then((response) => {
          commit("DELETE_TYPE_DOCUMENT", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getThematiqueDocuments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/thematique-document")
        .then((response) => {
          commit("SET_THEMATIQUE_DOCUMENT", response.data.themes);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createThematiqueDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/thematique-document", { payload }, config)
        .then((response) => {
          commit("ADD_THEMATIQUE_DOCUMENT", response.data.theme);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateThematiqueDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/thematique-document", { payload }, config)
        .then((response) => {
          commit("UPDATE_THEMATIQUE_DOCUMENT", response.data.theme);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteThematiqueDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/thematique-document/delete", { payload }, config)
        .then((response) => {
          commit("DELETE_THEMATIQUE_DOCUMENT", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLastFivePostedPublicationsDocument({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/document/publications/last-five")
        .then((response) => {
          commit("SET_LAST_FIVE_PUBLICATION_DOCUMENT", response.data.documents);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPostedPublicationsDocument({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/publications/posted/${page}/${limit}`)
        .then((response) => {
          commit("SET_PUBLICATION_DOCUMENT", response.data.documents);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDocumentsByUserId({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { page, limit, userId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/user/documents/${page}/${limit}/${userId}`, config)
        .then((response) => {
          commit("SET_DOCUMENTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDocuments({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/valide/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_DOCUMENTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUnValideDocuments({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/un-valide/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_DOCUMENTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/document", { payload }, config)
        .then((response) => {
          commit("ADD_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document", { payload }, config)
        .then((response) => {
          commit("UPDATE_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  valideDoc({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/valide", { payload }, config)
        .then((response) => {
          commit("UPDATE_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unValideDoc({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/unvalide", { payload }, config)
        .then((response) => {
          commit("UPDATE_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPublicationsDocument({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/document/publications")
        .then((response) => {
          commit("SET_PUBLICATION_DOCUMENT", response.data.publications);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDocumentsByOrganisationId(context, idOrganisation) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/single/organisation/posted/${idOrganisation}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDossierPaysDocument({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/document/pays")
        .then((response) => {
          commit("SET_DOSSIER_PAYS_DOCUMENT", response.data.documents);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDossierPaysDocumentByPaysId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/pays/${id}`)
        .then((response) => {
          commit("SET_DOSSIER_PAYS_DOCUMENT_PUBLIC", response.data.documents);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDocumentsByEaId(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/ea/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPublicationDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/document", { payload }, config)
        .then((response) => {
          commit("ADD_PUBLICATION_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createDocumentPays({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/document", { payload }, config)
        .then((response) => {
          commit("ADD_DOSSIER_PAYS_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePublicationDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document", { payload }, config)
        .then((response) => {
          commit("UPDATE_PUBLICATION_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateDossierPaysDocument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document", { payload }, config)
        .then((response) => {
          commit("UPDATE_DOSSIER_PAYS_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  PublishDocPays({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/publish", { payload }, config)
        .then((response) => {
          console.log(response.data.document);
          commit("UPDATE_DOSSIER_PAYS_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  PublishDoc({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/publish", { payload }, config)
        .then((response) => {
          console.log(response.data.document);
          commit("UPDATE_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unPublishDoc({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/unpublish", { payload }, config)
        .then((response) => {
          console.log(response.data.document);
          commit("UPDATE_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  PublishDocPublication({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/publish", { payload }, config)
        .then((response) => {
          console.log(response.data.document);
          commit("UPDATE_PUBLICATION_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unPublishDocPays({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/unpublish", { payload }, config)
        .then((response) => {
          console.log(response.data.document);
          commit("UPDATE_DOSSIER_PAYS_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unPublishDocPublication({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/unpublish", { payload }, config)
        .then((response) => {
          console.log(response.data.document);
          commit("UPDATE_PUBLICATION_DOCUMENT", response.data.document);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDoc({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/delete-file", { payload }, config)
        .then((response) => {
          commit("DELETE_DOCUMENT", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDocPublication({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/delete-file", { payload }, config)
        .then((response) => {
          commit("DELETE_PUBLICATION_DOCUMENT", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDocPays({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/delete-file", { payload }, config)
        .then((response) => {
          commit("DELETE_DOSSIER_PAYS_DOCUMENT", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  filterPublicationDocument(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/document/filter/publication", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
