/* =======================================================================================================
  File Name: modulePays.js
  Description: Pays Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./modulePaysState.js";
import mutations from "./modulePaysMutations.js";
import actions from "./modulePaysActions.js";
import getters from "./modulePaysGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
