export default {
  SET_PAYS_RESULT(state, data) {
    if (data.pays) {
      state.paysSearchResult = data.pays;
    } else {
      state.paysSearchResult = data;
    }
  },
  SET_PAYS(state, data) {
    state.pays = data;
  },
  ADD_PAYS(state, data) {
    state.pays.pays.unshift(data);
  },
  UPDATE_PAYS(state, data) {
    const index = state.pays.pays.findIndex((p) => p._id === data._id);
    Object.assign(state.pays.pays[index], data);
  },
  DELETE_PAYS(state, id) {
    const index = state.pays.pays.findIndex((p) => p._id === id);
    state.pays.splice(index, 1);
  },
  SET_REGION(state, data) {
    state.regions = data;
  },
  ADD_REGION(state, data) {
    state.regions.unshift(data);
  },
  UPDATE_REGION(state, data) {
    const index = state.regions.findIndex((r) => r._id === data._id);
    Object.assign(state.regions[index], data);
  },
  DELETE_REGION(state, id) {
    const index = state.regions.findIndex((r) => r._id === id);
    state.regions.splice(index, 1);
  },
};
