/* =======================================================================================================
  File Name: moduleTagAction.js
  Description: Tag Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  allTags({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/tags")
        .then((response) => {
          commit("SET_TAGS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  Tags({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/tags/${page}/${limit}`)
        .then((response) => {
          commit("SET_TAGS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchTags(context, searchValue) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/tags/${searchValue}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTag({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/tags", { payload }, config)
        .then((response) => {
          commit("ADD_TAG", response.data.tag);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTag({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/tags", { payload }, config)
        .then((response) => {
          commit("UPDATE_TAG", response.data.tag);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTag({ commit }, id) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const payload = {
      id: id,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/tags/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_TAG", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
