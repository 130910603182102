export default {
  SET_TAGS(state, data) {
    state.tags = data;
  },
  ADD_TAG(state, data) {
    state.tags.unshift(data);
  },
  UPDATE_TAG(state, data) {
    const index = state.tags.findIndex((p) => p._id === data._id);
    Object.assign(state.tags[index], data);
  },
  DELETE_TAG(state, id) {
    const index = state.tags.findIndex((p) => p._id === id);
    state.tags.splice(index, 1);
  },
};
