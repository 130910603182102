/* =======================================================================================================
  File Name: MutationSlider.js
  Description: Slider Mutation
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  SET_SLIDERS(state, data) {
    state.sliders = data;
  },
  SET_ACTIVE_SLIDERS(state, data) {
    state.homeSliders = data.slides;
  },
  SET_HOME_SLIDERS(state, data) {
    state.homeSliders = data.slides;
  },
  ADD_SLIDER(state, data) {
    state.sliders.unshift(data);
  },
  UPDATE_SLIDER(state, data) {
    const index = state.sliders.findIndex((s) => s._id == data._id);
    Object.assign(state.sliders[index], data);
  },
  DELETE_SLIDER(state, id) {
    const index = state.sliders.findIndex((s) => s._id == id);
    state.sliders.splice(index, 1);
  },
};
