/**============================================================
 * File Name: router.js
 * Description: Routers for vue-router. lazy loading is enabled
 * Object Structure: 
 *                    path => router path
 *                    name => router name
 *                    component(lazy loading) => component to load
 *                    meta: {
 *                      authRequired =>
 *                    }
 * 
 ==============================================================*/
import Vue from "vue";
import VueRouter from "vue-router";
//import auth from "../auth/authService";
//import Dashboard from "../views/pages/parametrage/dashbord/Dashboard.vue";
//import ProduitsSettings from "../views/pages/parametrage/produit/Produits.vue";
// import Meeting from "../views/pages/meeting/Meeting.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/pages/contact/Contact.vue"),
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: () => import("../views/pages/profile/Profile.vue"),
  },
  {
    path: "/profile/expert/:id",
    name: "ProfilePublic",
    component: () => import("../views/pages/profile/ProfilePublic.vue"),
  },
  {
    path: "/following/liste",
    name: "FollowingListe",
    component: () => import("../views/pages/profile/FollowingListe.vue"),
  },
  {
    path: "/pays",
    name: "Pays",
    component: () => import("../views/pages/pays/Pays.vue"),
  },
  {
    path: "/pays/:code",
    name: "DetailPays",
    component: () => import("../views/pages/pays/DetailPays.vue"),
  },
  {
    path: "/autodiagnostic",
    name: "Autodiagnostic",
    component: () => import("../views/pages/autodiagnostic/Autodiagnostic.vue"),
  },
  {
    path: "/compare/produit-finance",
    name: "Compare",
    component: () => import("../views/pages/autodiagnostic/Compare.vue"),
  },
  {
    path: "/autodiagnostic/detail/:slug",
    name: "DetailProduit",
    component: () => import("../views/pages/autodiagnostic/DetailProduit.vue"),
  },
  {
    path: "/reseautage",
    name: "Expert",
    component: () => import("../views/pages/resautage/experts/Expert.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/pages/login/Login.vue"),
  },
  {
    path: "/send-reset-password",
    name: "ResetPassword",
    component: () => import("../views/pages/login/ResetPassword.vue"),
  },
  {
    path: "/user/new-password/:token/:email",
    name: "NewPassword",
    component: () => import("../views/pages/login/NewPassword.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/pages/register/Register.vue"),
  },
  {
    path: "/register-confirmation",
    name: "ConfirmationRegisterMessage",
    component: () =>
      import("../views/pages/register/ConfirmationRegisterMessage.vue"),
  },
  {
    path: "/user/account-validate/:otp/:email",
    name: "VerificationAccount",
    component: () => import("../views/pages/register/VerificationAccount.vue"),
  },
  {
    path: "/user/register-more-info",
    name: "ProfileMoreInformation",
    component: () => import("../views/pages/profile/ProfileComplementInfo.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/pages/parametrage/Settings.vue"),
  },
  {
    path: "/settings/contacts",
    name: "SettingsContats",
    component: () => import("../views/pages/parametrage/contact/Contact.vue"),
  },
  {
    path: "/competences",
    name: "Formation",
    component: () => import("../views/pages/formation/Formation.vue"),
  },
  {
    path: "/competences/:slug",
    name: "DetailFormation",
    component: () => import("../views/pages/formation/DetailFormation.vue"),
  },
  {
    path: "/courses/:slug",
    name: "TrainingFormation",
    component: () => import("../views/pages/formation/ViewFormation.vue"),
  },
  {
    path: "/forum",
    name: "Forum",
    component: () => import("../views/pages/forum/Forum.vue"),
  },
  {
    path: "/forum/:slug",
    name: "ShowSubject",
    component: () => import("../views/pages/forum/ShowSubject.vue"),
  },
  {
    path: "/sujet/:slug",
    name: "ForumComment",
    component: () => import("../views/pages/forum/Comment.vue"),
  },
  {
    path: "/publications-nouvelles",
    name: "New",
    component: () => import("../views/pages/nouvelles/New.vue"),
  },
  {
    path: "/publications-nouvelles/:slug",
    name: "DetailNew",
    component: () => import("../views/pages/nouvelles/DetailNew.vue"),
  },
  {
    path: "/opportunites",
    name: "Opportunite",
    component: () => import("../views/pages/opportunite/Opportunite.vue"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("../views/pages/events/Events.vue"),
  },
  {
    path: "/events/:id",
    name: "DetailEvent",
    component: () => import("../views/pages/events/EventDetail.vue"),
  },
  {
    path: "/messagerie",
    name: "Messagerie",
    component: () => import("../views/pages/messagerie/Messagerie.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: () => import("../views/pages/organisation/Organisation.vue"),
  },
  {
    path: "/organisation/:id",
    name: "OrganisationDetail",
    component: () =>
      import("../views/pages/organisation/OrganisationDetail.vue"),
  },
  {
    path: "/search-result",
    name: "ResultSearchFinanceDurable",
    component: () =>
      import("../views/pages/search/ResultSearchFinanceDurable.vue"),
  },
  {
    path: "/search-result-point-focal",
    name: "ResultSearchPointFocal",
    component: () => import("../views/pages/search/ResultSearchPointFocal.vue"),
  },
  {
    path: "/partenaire/ea",
    name: "EntiteAccredite",
    component: () => import("../views/pages/ea/EntiteAccredite.vue"),
  },
  {
    path: "/partenaire/ea/:slug",
    name: "EntiteAccrediteDetail",
    component: () => import("../views/pages/ea/EntiteAccrediteDetail.vue"),
  },
  {
    path: "/compte",
    name: "Compte",
    component: () => import("../views/pages/compte/Compte.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/coordonnee",
    name: "Coordonnee",
    component: () => import("../views/pages/coordonnee/Coordonnee.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mon-expertise",
    name: "MonExpertise",
    component: () => import("../views/pages/expertise/MonExpertise.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mes-recherches",
    name: "MesRecherches",
    component: () => import("../views/pages/recherche/MesRecherches.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mes-contributions",
    name: "MesContributions",
    component: () => import("../views/pages/contribution/MesContributions.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mes-communications",
    name: "MesCommunications",
    component: () =>
      import("../views/pages/communication/MesCommunications.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/panel-administrateur",
    name: "Admin",
    component: () => import("../views/pages/admin/Admin.vue"),
    meta: {
      authRequired: true,
    },
  },
  // {
  //   path: "/meeting",
  //   name: "Meeting",
  //   component: Meeting,
  // },
  /* {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/About.vue"),
  }, */
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("Token");
  if (to.meta.authRequired && !token) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
