/* =======================================================================================================
  File Name: StateSearch.js
  Description: Search State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou Eric Martial
  ======================================================================================================= */

export default {
  experts: [],
  produits: [],
  formations: [],
  documents: [],
  organisations: [],
  forums: [],
  events: [],
  nda: [],
};
