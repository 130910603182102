export default {
  SET_PROJETS(state, data) {
    state.projets = data;
  },
  SET_PROJETS_PAYS(state, data) {
    state.projets_pays = data;
  },
  ADD_PROJET(state, data) {
    state.projets.projets.unshift(data);
  },
  UPDATE_PROJET(state, data) {
    const index = state.projets.projets.findIndex((p) => p._id === data._id);
    Object.assign(state.projets.projets[index], data);
  },
  DELETE_PROJET(state, id) {
    const index = state.projets.projets.findIndex((p) => p._id === id);
    state.projets.projets.splice(index, 1);
  },
};
