/* =======================================================================================================
  File Name: StateOrganisation.js
  Description: Organisation State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  authUserOrganisations: [],
  organisations: [],
  organisationsFournisseur: [],
  typeOrganisations: [],
};
