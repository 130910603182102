/* =======================================================================================================
  File Name: StateUser.js
  Description: User State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  userInfo: null,
  users: [],
  public_users: [],
  demandes_expert: [],
  experts: [],
  auth_user_followings: [],
  experts_suivi: [],
  searchExpertsResults: [],
  listesChat: [],
  messagesOnWrite: [],
  messagePerUser: [],
  conversationsPerUser: [],
};
