/* =======================================================================================================
  File Name: StatePays.js
  Description: Pays State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  pays: [],
  paysSearchResult: [],
  regions: [
    {
      _id: 1,
      nom: "Asie",
    },
    {
      _id: 2,
      nom: "Afrique",
    },
    {
      _id: 3,
      nom: "Europe",
    },
    {
      _id: 4,
      nom: "Amérique du Nord",
    },
    {
      _id: 5,
      nom: "Amérique du Sud",
    },
    {
      _id: 6,
      nom: "Océanie",
    },
  ],
};
