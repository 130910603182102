/* =======================================================================================================
  File Name: moduleProduit.js
  Description: Produit Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleProduitState.js";
import mutations from "./moduleProduitMutations.js";
import actions from "./moduleProduitActions.js";
import getters from "./moduleProduitGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
