/* =======================================================================================================
  File Name: StateNouvelle.js
  Description: Nouvelle State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  nouvelles_pays: [],
  nouvelles_publie: [],
  nouvelles: [],
  publications: [],
};
