/* =======================================================================================================
  File Name: StateProjet.js
  Description: Projet State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou Eric Martial
  ======================================================================================================= */

export default {
  projets: [],
  projets_pays: [],
};
