/* =======================================================================================================
  File Name: GetterSlider.js
  Description: Slider Getter
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  getSliderById: (state) => (id) => {
    return state.sliders.length > 0
      ? state.sliders.find((s) => s._id === id)
      : null;
  },
};
