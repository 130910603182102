export default {
  SET_CANAUX(state, data) {
    state.canaux = data;
  },
  ADD_CANAL(state, data) {
    state.canaux.unshift(data);
  },
  UPDATE_CANAL(state, data) {
    const index = state.canaux.findIndex((c) => c._id === data._id);
    Object.assign(state.canaux[index], data);
  },
  DELETE_CANAL(state, data) {
    const index = state.canaux.findIndex((c) => c._id === data._id);
    state.canaux.splice(index, 1);
  },
  SET_ADMIN_DISCUSSION(state, data) {
    state.adminDiscussions = data;
  },
  UPDATE_ADMIN_DISCUSSION(state, data) {
    const index = state.adminDiscussions.discussions.findIndex(
      (d) => d._id === data._id
    );
    Object.assign(state.adminDiscussions.discussions[index], data);
  },
  DELETE_ADMIN_DISCUSSION(state, data) {
    const index =
      state.adminDiscussions.discussions.length > 0
        ? state.adminDiscussions.discussions.findIndex(
            (d) => d._id === data._id
          )
        : null;
    if (index === 0 || index > 0)
      state.adminDiscussions.discussions.splice(index, 1);
  },
  SET_FORUM_QUESTION(state, data) {
    state.discussions = data;
  },
  ADD_FORUM_QUESTION(state, data) {
    state.discussions.discussions.unshift(data);
  },
  UPDATE_FORUM_QUESTION(state, data) {
    const index = state.discussions.discussions.findIndex(
      (q) => q._id === data._id
    );
    Object.assign(state.discussions.discussions[index], data);
  },
  DELETE_FORUM_QUESTION(state, data) {
    const index = state.discussions.discussions.findIndex(
      (q) => q._id === data._id
    );
    state.discussions.splice(index, 1);
  },
};
