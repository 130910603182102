/* =======================================================================================================
  File Name: moduleProjet.js
  Description: Projet Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleProjetState.js";
import mutations from "./moduleProjetMutations.js";
import actions from "./moduleProjetActions.js";
import getters from "./moduleProjetGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
