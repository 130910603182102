/* =======================================================================================================
  File Name: moduleDocument.js
  Description: Document Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleDocumentState.js";
import mutations from "./moduleDocumentMutations.js";
import actions from "./moduleDocumentActions.js";
import getters from "./moduleDocumentGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
