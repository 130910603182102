import axios from "@/axios.js";
export default {
  searchUsers({ commit }, search_value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/search/user/${search_value}`)
        .then((response) => {
          commit("SET_SEARCH_EXPERTS", response.data.users);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchProduit({ commit }, search_value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/produits/${search_value}`)
        .then((response) => {
          commit("SET_SEARCH_PRODUITS", response.data.produits);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchProduitFinanceDurable({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/produits/searchProduitFinanceDurable", { payload })
        .then((response) => {
          commit("SET_SEARCH_PRODUITS", response.data.results);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchFormationFinanceDurable({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/formations/searchFormationFinanceDurable", { payload })
        .then((response) => {
          commit("SET_SEARCH_FORMATIONS", response.data.results);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchPointFocalMecanisme({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/nda/search-point-focal-mecanisme", { payload })
        .then((response) => {
          commit("SET_SEARCH_NDA", response.data.results);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchExpertRegionalInternational(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/contact/search-expert-regional-international", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchOrganisation({ commit }, search_value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/search/organisation/${search_value}`)
        .then((response) => {
          commit("SET_SEARCH_ORGANISATIONS", response.data.organisations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchDocument({ commit }, search_value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/document/search/all/type/pub/doc/pays/${search_value}`)
        .then((response) => {
          commit("SET_SEARCH_DOCUMENTS", response.data.documents);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetSearchData({ commit }) {
    commit("RESET_SEARCH_DATA");
  },
};
