/* =======================================================================================================
  File Name: MutationNouvelle.js
  Description: Nouvelle Mutation
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  SET_NOUVELLES(state, data) {
    state.nouvelles = data;
  },
  SET_NOUVELLES_PAYS(state, data) {
    state.nouvelles_pays = data;
  },
  SET_NOUVELLES_PUBLIER(state, data) {
    state.nouvelles_publie = data;
  },
  ADD_NOUVELLE(state, data) {
    state.nouvelles.unshift(data);
  },
  UPDATE_NOUVELLE(state, data) {
    const index = state.nouvelles.findIndex((n) => n._id == data._id);
    Object.assign(state.nouvelles[index], data);
  },
  DELETE_NOUVELLE(state, id) {
    const index = state.nouvelles.findIndex((n) => n._id == id);
    state.nouvelles.splice(index, 1);
  },
  SET_PUBLICATIONS(state, data) {
    state.publications = data;
  },
  ADD_PUBLICATION(state, data) {
    state.publications.unshift(data);
  },
  UPDATE_PUBLICATION(state, data) {
    const index = state.publications.findIndex((n) => n._id == data._id);
    Object.assign(state.publications[index], data);
  },
  DELETE_PUBLICATION(state, id) {
    const index = state.publications.findIndex((n) => n._id == id);
    state.publications.splice(index, 1);
  },
};
