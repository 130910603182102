// axios
import axios from "axios";

//<<<<<<< M.Djezou
//const baseURL = "http://127.0.0.1:3000";
const baseURL = "https://api.pole-francophone-finance-climat.beyima.com";
//const baseURL = "https://api.pole-francophone-finance-climat.marketys.ci";
//=======
// const baseURL =
//   "https://api.chat.pole-francophone-finance-climat.fovea-erp.com";
//>>>>>>> main
export default axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  baseURL,
  // You can add your headers here
});
