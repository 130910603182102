/* =======================================================================================================
  File Name: moduleProduitAction.js
  Description: Produit Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  addProduitInComparateur({ commit }, payload) {
    commit("ADD_PRODUIT_IN_COMPARATEUR", payload);
  },
  resetProduitInComparateur({ commit }) {
    commit("RESET_PRODUIT_IN_COMPARATEUR");
  },
  autodiagnostic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/produits/autodiagnostic", { payload })
        .then((response) => {
          commit("RESULT_AUTODIAGNOSTIC", response.data.produits);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProduits({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/produits/${page}/${limit}`)
        .then((response) => {
          commit("SET_PRODUITS", response.data.produits);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUnvalideProduits({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/produits/unvalide/${page}/${limit}`)
        .then((response) => {
          commit("SET_PRODUITS", response.data.produits);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProduitsByUserId({ commit }, payload) {
    const { page, limit, id } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/produits/${page}/${limit}/${id}`, config)
        .then((response) => {
          commit("SET_PRODUITS", response.data.produits);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProduitBySlug(context, slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/produits/single/produit/get/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProduitsByFournisseurId(context, idFour) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/produits/get/single/by/fournisseur/${idFour}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTypeProduits({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/type-produits")
        .then((response) => {
          commit("SET_TYPE_PRODUITS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createProduit({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/produits", { payload }, config)
        .then((response) => {
          commit("ADD_PRODUITS", response.data.produit);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTypeProduit({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/type-produits", { payload }, config)
        .then((response) => {
          commit("ADD_TYPE_PRODUITS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProduit({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/produits", { payload }, config)
        .then((response) => {
          commit("UPDATE_PRODUIT", response.data.produit);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  validateProduit({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/produits/validate", { payload }, config)
        .then((response) => {
          commit("UPDATE_PRODUIT", response.data.produit);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unValidateProduit({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/produits/unvalidate", { payload }, config)
        .then((response) => {
          commit("UPDATE_PRODUIT", response.data.produit);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTypeProduit({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/type-produits", { payload }, config)
        .then((response) => {
          commit("UPDATE_TYPE_PRODUIT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteProduit({ commit }, id) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/produits/${id}`, config)
        .then((response) => {
          commit("DELETE_PRODUIT", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTypeProduit({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/type-produits/${id}`)
        .then((response) => {
          commit("DELETE_TYPE_PRODUIT", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  organisationCheckAll({ commit }) {
    commit("ORGANISATION_CHECK_ALL");
  },
  organisationDeselectedAll({ commit }) {
    commit("ORGANISATION_DESELECTED_ALLL");
  },
  getInstruments({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/instruments`)
        .then((response) => {
          commit("SET_INSTRUMENT", response.data.instruments);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createInstrument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/instruments`, { payload }, config)
        .then((response) => {
          commit("ADD_INSTRUMENT", response.data.instrument);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateInstrument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/instruments`, { payload }, config)
        .then((response) => {
          commit("UPDATE_INSTRUMENT", response.data.instrument);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteInstrument({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/instruments/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_INSTRUMENT", response.data.instrument);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchMecanisme(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/mecanismes/search`, { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMecanismes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/mecanismes`)
        .then((response) => {
          commit("SET_MECANISME", response.data.mecanismes);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createMecanisme({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/mecanismes`, { payload }, config)
        .then((response) => {
          commit("ADD_MECANISME", response.data.mecanisme);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMecanisme({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/mecanismes`, { payload }, config)
        .then((response) => {
          commit("UPDATE_MECANISME", response.data.mecanisme);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMecanisme({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/mecanismes/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_MECANISME", response.data.mecanisme);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getNDA({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/nda/${page}/${limit}`)
        .then((response) => {
          commit("SET_NDA", response.data.nda);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPointFocalByCountry(context, idPays) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/nda/${idPays}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createNDA({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/nda`, { payload }, config)
        .then((response) => {
          commit("ADD_NDA", response.data.nda);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateNDA({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/nda`, { payload }, config)
        .then((response) => {
          commit("UPDATE_NDA", response.data.nda);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteNDA({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/nda/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_NDA", response.data.nda);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEntitesAccredite({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/entites-acredites/${page}/${limit}`)
        .then((response) => {
          commit("SET_ENTITES_ACCREDITE", response.data.entiteAccredite);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  filterEA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/entites-acredites/filter/eas`, { payload })
        .then((response) => {
          commit("SET_ENTITES_ACCREDITE", response.data.entiteAccredite);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEAByCountry(context, idPays) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/entites-acredites/${idPays}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEABySlug(context, slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/entites-acredites/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchEntiteAccredite(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/entites-acredites/result/search/eas/admin`, { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEntiteAccredite({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/entites-acredites`, { payload }, config)
        .then((response) => {
          commit("ADD_ENTITES_ACCREDITE", response.data.entiteAccredite);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEntiteAccredite({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/entites-acredites`, { payload }, config)
        .then((response) => {
          commit("UPDATE_ENTITES_ACCREDITE", response.data.entiteAccredite);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  entiteAccrediteLogo({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/entites-acredites/update/ea/logo`, { payload }, config)
        .then((response) => {
          commit("UPDATE_ENTITES_ACCREDITE", response.data.entiteAccredite);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deteleEntiteAccredite({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/entites-acredites/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_ENTITES_ACREDITE", response.data.entiteAccredite);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  secteurCheckAll({ commit }) {
    commit("SECTEUR_CHECK_ALL");
  },
  secteurDeselectedAll({ commit }) {
    commit("SECTEUR_DESELECTED_ALLL");
  },
  instrumentCheckAll({ commit }) {
    commit("INSTRUMENT_CHECK_ALL");
  },
  instrumentDeselectedAll({ commit }) {
    commit("INSTRUMENT_DESELECTED_ALLL");
  },
};
