export default {
  SET_SEARCH_EXPERTS(state, data) {
    state.experts = data;
  },
  SET_SEARCH_PRODUITS(state, data) {
    state.produits = data;
  },
  SET_SEARCH_FORMATIONS(state, data) {
    state.formations = data;
  },
  SET_SEARCH_DOCUMENTS(state, data) {
    state.documents = data;
  },
  SET_SEARCH_ORGANISATIONS(state, data) {
    state.organisations = data;
  },
  SET_SEARCH_FORUMS(state, data) {
    state.forums = data;
  },
  SET_SEARCH_EVENTS(state, data) {
    state.events = data;
  },
  SET_SEARCH_NDA(state, data) {
    state.nda = data;
  },
  RESET_SEARCH_DATA(state) {
    state.experts = [];
    state.produits = [];
    state.formations = [];
    state.documents = [];
    state.organisations = [];
    state.forums = [];
    state.events = [];
  },
};
