/* =======================================================================================================
  File Name: moduleNouvelle.js
  Description: Nouvelle Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleNouvelleState.js";
import mutations from "./moduleNouvelleMutations.js";
import actions from "./moduleNouvelleActions.js";
import getters from "./moduleNouvelleGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
