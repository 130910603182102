export default {
  SET_FORMATIONS(state, data) {
    state.formations = data;
  },
  SET_FORMATIONS_EPAVILLON(state, data) {
    state.epavillons = data;
  },
  ADD_FORMATION(state, data) {
    state.formations.formations.unshift(data);
  },
  UPDATE_FORMATION(state, data) {
    const index = state.formations.findIndex((p) => p._id === data._id);
    Object.assign(state.formations[index], data);
  },
  DELETE_FORMATION(state, id) {
    const index = state.formations.formations.findIndex((p) => p._id === id);
    state.formations.formations.splice(index, 1);
  },
  SET_TYPE_FORMATIONS(state, data) {
    state.typeFormations = data;
  },
  ADD_TYPE_FORMATION(state, data) {
    state.typeFormations.unshift(data);
  },
  UPDATE_TYPE_FORMATION(state, data) {
    const index = state.typeFormations.findIndex((p) => p._id === data._id);
    Object.assign(state.typeFormations[index], data);
  },
  DELETE_TYPE_FORMATION(state, id) {
    const index = state.typeFormations.findIndex((p) => p._id === id);
    state.typeFormations.splice(index, 1);
  },
  SET_CATEGORIE_FORMATIONS(state, data) {
    state.categories = data;
  },
  ADD_CATEGORIE_FORMATION(state, data) {
    state.categories.unshift(data);
  },
  UPDATE_CATEGORIE_FORMATION(state, data) {
    const index = state.categories.findIndex((p) => p._id === data._id);
    Object.assign(state.categories[index], data);
  },
  DELETE_CATEGORIE_FORMATION(state, id) {
    const index = state.categories.findIndex((p) => p._id === id);
    state.categories.splice(index, 1);
  },
  SET_OBJECTIF_FORMATIONS(state, data) {
    state.objectifs = data;
  },
  ADD_OBJECTIF_FORMATION(state, data) {
    state.objectifs.unshift(data);
  },
  UPDATE_OBJECTIF_FORMATION(state, data) {
    const index = state.objectifs.findIndex((p) => p._id === data._id);
    Object.assign(state.objectifs[index], data);
  },
  DELETE_OBJECTIF_FORMATION(state, id) {
    const index = state.objectifs.findIndex((p) => p._id === id);
    state.objectifs.splice(index, 1);
  },
  SET_CIBLE_FORMATIONS(state, data) {
    state.cibles = data;
  },
  ADD_CIBLE_FORMATION(state, data) {
    state.cibles.unshift(data);
  },
  UPDATE_CIBLE_FORMATION(state, data) {
    const index = state.cibles.findIndex((p) => p._id === data._id);
    Object.assign(state.cibles[index], data);
  },
  DELETE_CIBLE_FORMATION(state, id) {
    const index = state.cibles.findIndex((p) => p._id === id);
    state.cibles.splice(index, 1);
  },
  SET_APPRENTISSAGE(state, data) {
    state.apprentissages = data;
  },
  ADD_APPRENTISSAGE(state, data) {
    state.apprentissages.unshift(data);
  },
  CURRENT_APPRENTISAGE(state, data) {
    state.currentApprentissage = data;
  },
  UPDATE_APPRENTISSAGE(state, data) {
    const index = state.apprentissages.findIndex((a) => a._id === data._id);
    Object.assign(state.apprentissages[index], data);
  },
  DELETE_APPRENTISSAGE(state, id) {
    const index = state.apprentissages.findIndex((a) => a._id === id);
    state.apprentissages.splice(index, 1);
  },
};
