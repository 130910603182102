/* =======================================================================================================
  File Name: moduleOrganisation.js
  Description: Organisation Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleOrganisationState.js";
import mutations from "./moduleOrganisationMutations";
import actions from "./moduleOrganisationActions.js";
import getters from "./moduleOrganisationGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
