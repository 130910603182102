export default {
  getTypeOrganisationById: (state) => (id) => {
    return state.typeOrganisations
      ? state.typeOrganisations.types
        ? state.typeOrganisations.types.find((o) => o._id === id)
        : null
      : null;
  },
  getOrganisationById: (state) => (id) => {
    return state.organisations.length > 0
      ? state.organisations.find((o) => o._id === id)
      : null;
  },
};
