export default {
  SET_ORGANISATIONS(state, data) {
    state.organisations = data;
  },
  SET_AUTH_USER_ORGANISATIONS(state, data) {
    state.authUserOrganisations = data;
  },
  ADD_ORGANISATION(state, data) {
    state.organisations.organisations.unshift(data);
  },
  UPDATE_ORGANISATION(state, data) {
    const index = state.organisations.organisations.findIndex(
      (p) => p._id === data._id
    );
    if (index) Object.assign(state.organisations.organisations[index], data);
  },
  UPDATE_AUTH_USER_ORGANISATION(state, data) {
    const index = state.authUserOrganisations.findIndex(
      (p) => p._id === data._id
    );
    if (index) {
      Object.assign(state.authUserOrganisations[index], data);
    }
  },
  DELETE_ORGANISATION(state, id) {
    const index = state.organisations.organisations.findIndex(
      (p) => p._id === id
    );
    state.organisations.organisations.splice(index, 1);
  },
  DELETE_AUTH_USER_ORGANISATION(state, id) {
    const index = state.authUserOrganisations.findIndex((p) => p._id === id);
    state.authUserOrganisations.splice(index, 1);
  },
  SET_ORGANISATIONS_FOURNISSEURS(state, data) {
    state.organisationsFournisseur = data;
  },
  SET_TYPE_ORGANISATION(state, data) {
    state.typeOrganisations = data;
  },
  ADD_TYPE_ORGANISATION(state, data) {
    state.typeOrganisations.unshift(data);
  },
  UPDATE_TYPE_ORGANISATION(state, data) {
    const index = state.typeOrganisations.findIndex((p) => p._id === data._id);
    Object.assign(state.typeOrganisations[index], data);
  },
  DELETE_TYPE_ORGANISATION(state, id) {
    const index = state.typeOrganisations.findIndex((p) => p._id === id);
    state.typeOrganisations.splice(index, 1);
  },
};
