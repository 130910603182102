/* =======================================================================================================
  File Name: GetterNouvelle.js
  Description: Nouvelle Getter
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  getNouvelleById: (state) => (id) => {
    return state.nouvelles_publie.nouvelles.length > 0
      ? state.nouvelles_publie.nouvelles.find((n) => n._id === id)
      : null;
  },
  getNouvelleBySlug: (state) => (slug) => {
    return (
      state.nouvelles_publie.nouvelles
        ? state.nouvelles_publie.nouvelles.length > 0
        : null
    )
      ? state.nouvelles_publie.nouvelles.find((n) => n.slug === slug)
      : null;
  },
  getPublicationById: (state) => (id) => {
    return state.publications.length > 0
      ? state.publications.find((p) => p._id === id)
      : null;
  },
  getPublicationBySlug: (state) => (slug) => {
    return state.publications.length > 0
      ? state.publications.find((p) => p.slug === slug)
      : null;
  },
  getNouvelleByTypeNouvelle: (state) => (type) => {
    return state.nouvelles_publie.nouvelles.length > 0
      ? state.nouvelles_publie.nouvelles.filter((n) => n.type_nouvelle === type)
      : null;
  },
};
