export default {
  suiviByUserId: (state) => (id) => {
    const isfollow = state.auth_user_followings.followings
      ? state.auth_user_followings.followings.length > 0
        ? state.auth_user_followings.followings.find(
            (u) => u.userFollowing._id === id
          )
        : false
      : false;

    console.log(isfollow);
    return isfollow;
  },
  isAdministrateur: (state) => {
    return state.userInfo
      ? state.userInfo.role.includes("Administrateur")
      : null;
  },
  getFiveAuthFollowingUser: (state) => {
    return state.userInfo ? state.userInfo.following_list.slice(0, 4) : null;
  },
  getExpertInfoById: (state) => (id) => {
    return (state.experts ? state.experts.users : null)
      ? state.experts.users.find((e) => e._id === id)
      : null;
  },
  listExpertConversetionsByRecent: (state) => {
    const experts =
      state.experts_suivi.length > 0
        ? state.experts_suivi.sort((expertA, expertB) => {
            const LastIndexB =
              expertB.messages.length > 0 ? expertB.messages.length - 1 : null;
            const LastIndexA =
              expertA.messages.length > 0 ? expertA.messages.length - 1 : null;
            console.log("LastIndexB:", LastIndexB);
            console.log("LastIndexA:", LastIndexA);
            if (LastIndexB && LastIndexA) {
              expertB.messages[LastIndexB].createdAt -
                expertA.messages[LastIndexA].createdAt;
            }
          })
        : [];
    return experts;
  },
};
