import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./assets/tailwind.css";
import Multiselect from "vue-multiselect";
import VueScrollactive from "vue-scrollactive";
import VueSweetalert2 from "vue-sweetalert2";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueSocialSharing from "vue-social-sharing";
import VueTypedJs from "vue-typed-js";

Vue.use(VueTypedJs);

Vue.use(VueSocialSharing);

Vue.use(VueTelInput);

import VModal from "vue-js-modal";
Vue.use(VModal);

import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(VueFileAgent);

import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;
/* add icons to the library */
library.add(faUserSecret);

// register globally
Vue.component("multiselect", Multiselect);

Vue.use(VueScrollactive);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

const moment = require("moment");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
