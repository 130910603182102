/* =======================================================================================================
  File Name: StateFormation.js
  Description: Formation State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  formations: [],
  epavillons: [],
  typeFormations: [],
  categories: [],
  objectifs: [],
  cibles: [],
  apprentissages: [],
  currentApprentissage: {},
};
