import axios from "@/axios.js";

export default {
  getEvents({ commit }, payload) {
    const { currentPageEvent, limitPerPageEvent } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/events/${currentPageEvent}/${limitPerPageEvent}`)
        .then((response) => {
          commit("SET_EVENTS", response.data.events);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  filterEvents({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/events/filter`, { payload })
        .then((response) => {
          const data = {
            events: response.data.events,
            totalPages: 1,
            currentPage: 1,
          };
          commit("SET_EVENTS", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUnValideEvents({ commit }, payload) {
    const { currentPageEvent, limitPerPageEvent } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/events/unvalide/${currentPageEvent}/${limitPerPageEvent}`,
          config
        )
        .then((response) => {
          commit("SET_UNVALIDE_EVENTS", response.data.events);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEventById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/events/single/event/info/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEventsByUserId({ commit }, payload) {
    const { currentPageEvent, limitPerPageEvent, userId } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/events/${currentPageEvent}/${limitPerPageEvent}/${userId}`,
          config
        )
        .then((response) => {
          commit("SET_USER_AUTH_EVENTS", response.data.events);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEvent({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/events", { payload }, config)
        .then((response) => {
          commit("ADD_EVENT", response.data.event);
          commit("ADD_USER_AUTH_EVENTS", response.data.event);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEvent({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/events", { payload }, config)
        .then((response) => {
          commit("UPDATE_EVENT", response.data.event);
          commit("UPDATE_USER_AUTH_EVENT", response.data.event);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  valideEventByAdmin({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/events/validate", { payload }, config)
        .then((response) => {
          commit("UPDATE_UNVALIDE_EVENT", response.data.event);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unValideEvent({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/events/unvalidate", { payload }, config)
        .then((response) => {
          commit("UPDATE_EVENT", response.data.event);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEvent({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/events/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_EVENT", id);
          commit("DELETE_USER_AUTH_EVENT", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCategorieEvents({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/event-categories")
        .then((response) => {
          commit("SET_EVENTS_CATEGORIE", response.data.categories);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createCategorieEvent({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/event-categories", { payload }, config)
        .then((response) => {
          commit("ADD_EVENT_CATEGORIE", response.data.categorie);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCategorieEvent({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/event-categories", { payload }, config)
        .then((response) => {
          commit("UPDATE_EVENT_CATEGORIE", response.data.categorie);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCategorieEvent({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/event-categories/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_EVENT_CATEGORIE", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
