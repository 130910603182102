export default {
  SET_PUBLIQUES_CIBLES(state, data) {
    state.publiquesCibles = data;
  },
  ADD_PUBLIQUE_CIBLE(state, data) {
    state.publiquesCibles.unshift(data);
  },
  UPDATE_PUBLIQUE_CIBLE(state, data) {
    const index = state.publiquesCibles.findIndex((p) => p._id === data._id);
    Object.assign(state.publiquesCibles[index], data);
  },
  DELETE_PUBLIQUE_CIBLE(state, data) {
    const index = state.publiquesCibles.findIndex((p) => p._id === data._id);
    state.publiquesCibles.splice(index, 1);
  },
  SET_DOMAINES(state, data) {
    state.domaines = data;
  },
  ADD_DOMAINE(state, data) {
    state.domaines.unshift(data);
  },
  UPDATE_DOMAINE(state, data) {
    const index = state.domaines.findIndex((d) => d._id === data._id);
    Object.assign(state.domaines[index], data);
  },
  DELETE_DOMAINE(state, data) {
    const index = state.domaines.findIndex((d) => d._id === data._id);
    state.domaines.splice(index, 1);
  },
};
