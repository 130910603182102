/* =======================================================================================================
  File Name: ModuleSlider.js
  Description: Slider Module
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

import state from "./moduleSliderState.js";
import mutations from "./moduleSliderMutations.js";
import actions from "./moduleSliderActions.js";
import getters from "./moduleSliderGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
