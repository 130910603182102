export default {
  getProjetById: (state) => (id) => {
    const projet =
      state.projets.length > 0 ? state.projets.find((p) => p._id === id) : null;
    return projet;
  },
  getProjetByIdPays: (state) => (id) => {
    const projet =
      state.projets_pays.length > 0
        ? state.projets_pays.find((p) => p._id === id)
        : null;
    return projet;
  },
};
