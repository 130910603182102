/* =======================================================================================================
  File Name: StateEvent.js
  Description: Event State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  events: [],
  unvalideEvents: [],
  userAuthEvents: [],
  categories: [],
};
