/* =======================================================================================================
  File Name: moduleParametre.js
  Description: Parametre Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleParametreState.js";
import mutations from "./moduleParametreMutations.js";
import actions from "./moduleParametreActions.js";
import getters from "./moduleParametreGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
