import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleUser from "./user/moduleUser.js";
import modulePays from "./pays/modulePays.js";
import moduleOrganisation from "./organisation/moduleOrganisation.js";
import moduleProduit from "./produit/moduleProduit";
import moduleProjet from "./projet/moduleProjet";
import moduleFormation from "./formation/moduleFormation.js";
import moduleTag from "./tag/moduleTag.js";
import moduleNouvelle from "./nouvelle/moduleNouvelle.js";
import moduleSlider from "./slider/moduleSlider.js";
import moduleEvent from "./event/moduleEvent.js";
import moduleDocument from "./document/moduleDocument.js";
import moduleForum from "./forum/moduleForum.js";
import moduleSearch from "./search/moduleSearch";
import moduleContact from "./contact/moduleContact.js";
import moduleParametre from "./parametre/moduleParametre.js";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    user: moduleUser,
    pays: modulePays,
    organisation: moduleOrganisation,
    produit: moduleProduit,
    projet: moduleProjet,
    formation: moduleFormation,
    tag: moduleTag,
    nouvelle: moduleNouvelle,
    slider: moduleSlider,
    event: moduleEvent,
    document: moduleDocument,
    forum: moduleForum,
    search: moduleSearch,
    contact: moduleContact,
    parametre: moduleParametre,
  },
});
