/* =======================================================================================================
  File Name: StateTag.js
  Description: Tag State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  tags: [
    {
      _id: 1,
      nom: "finance climat",
    },
    {
      _id: 2,
      nom: "cop27",
    },
  ],
};
