export default {
  getResultBySlug: (state) => (slug) => {
    const result =
      state.results.length > 0
        ? state.results.find((r) => r.slug === slug)
        : null;
    return result;
  },
  getProduitById: (state) => (id) => {
    const produit =
      state.produits.length > 0
        ? state.produits.find((p) => p._id === id)
        : null;
    return produit;
  },
  getProduitByTypeProduit: (state) => (type) => {
    const produit =
      state.produits.length > 0
        ? state.produits.find((p) => p.type_de_produit === type)
        : null;
    return produit;
  },
};
