/* =======================================================================================================
  File Name: StateProduit.js
  Description: Produit State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  results: [],
  comparateur: [],
  mecanismes: [],
  produits: [
    {
      _id: "1",
      titre: "Le fond vert pour le climat(GCF)",
      organisation: "Banque commerciale",
      pays: "Bénin",
      instrument: "Prêt commercial",
      secteur: "Villes",
      checked: false,
      description:
        "Le Fonds vert pour le climat (GCF) a été créé en 2010 et vise à promouvoir le changement de paradigme vers des voies de développement à faibles émissions et résilientes au changement climatique en aidant les pays en développement à limiter ou réduire leurs émissions de gaz à effet de serre et à s'adapter aux impacts de changement climatique, en tenant compte des besoins des pays en développement particulièrement vulnérables aux effets néfastes du changement climatique. Le Fonds vert pour le climat (GCF) est une entité opérationnelle du mécanisme financier de la CCNUCC et de l'Accord de Paris.",
    },
    {
      _id: "2",
      titre:
        "Le fond vert pour le climat(GCF) - Mécanisme de préparation de projets",
      organisation: "Banque multilatérale",
      pays: "Côte d'ivoire",
      instrument: "Don",
      secteur: "Agriculture",
      checked: false,
      description:
        "Le Fonds vert pour le climat (GCF) a été créé en 2010 et vise à promouvoir le changement de paradigme vers des voies de développement à faibles émissions et résilientes au changement climatique en aidant les pays en développement à limiter ou réduire leurs émissions de gaz à effet de serre et à s'adapter aux impacts de changement climatique, en tenant compte des besoins des pays en développement particulièrement vulnérables aux effets néfastes du changement climatique.",
    },
    {
      _id: "3",
      titre:
        "Le fond vert pour le climat(GCF) - Mécanisme de préparation de projets",
      organisation: "Banque multilatérale",
      pays: "Côte d'ivoire",
      instrument: "Don",
      secteur: "Agriculture",
      checked: false,
      description:
        "Le Fonds vert pour le climat (GCF) a été créé en 2010 et vise à promouvoir le changement de paradigme vers des voies de développement à faibles émissions et résilientes au changement climatique en aidant les pays en développement à limiter ou réduire leurs émissions de gaz à effet de serre et à s'adapter aux impacts de changement climatique, en tenant compte des besoins des pays en développement particulièrement vulnérables aux effets néfastes du changement climatique.",
    },
  ],
  typeProduits: [
    {
      _id: 1,
      nom: "Assistance technique",
      description: "",
    },
    {
      _id: 2,
      nom: "Don",
      description: "",
    },
    {
      _id: 3,
      nom: "Pret",
      description: "",
    },
    {
      _id: 4,
      nom: "Investissement",
      description: "",
    },
    {
      _id: 5,
      nom: "Garantie",
      description: "",
    },
    {
      _id: 6,
      nom: "Formation",
      description: "",
    },
    {
      _id: 7,
      nom: "Subventions",
      description: "",
    },
    {
      _id: 8,
      nom: "Subventions",
      description: "",
    },
    {
      _id: 9,
      nom: "Autre",
      description: "",
    },
  ],
  secteurs: [
    {
      _id: 1,
      nom: "Tout",
      checked: false,
    },
    {
      _id: 1,
      nom: "Agriculture",
      checked: false,
    },
    {
      _id: 2,
      nom: "Villes",
      checked: false,
    },
    {
      _id: 3,
      nom: "Réduction des risques de catastrophe",
      checked: false,
    },
    {
      _id: 4,
      nom: "Reprise économique",
      checked: false,
    },
    {
      _id: 5,
      nom: "Éducation",
      checked: false,
    },
    {
      _id: 6,
      nom: "Efficacité énergétique",
      checked: false,
    },
    {
      _id: 7,
      nom: "Foresterie et utilisation des terres",
      checked: false,
    },
    {
      _id: 8,
      nom: "Le genre",
      checked: false,
    },
    {
      _id: 9,
      nom: "Santé",
      checked: false,
    },
    {
      _id: 10,
      nom: "Industrie et infrastructures",
      checked: false,
    },
    {
      _id: 11,
      nom: "Emplois",
      checked: false,
    },
    {
      _id: 12,
      nom: "Solutions et écosystème basés sur la nature",
      checked: false,
    },
    {
      _id: 13,
      nom: "Prestations de service",
      checked: false,
    },
    {
      _id: 14,
      nom: "Océans et Côtes",
      checked: false,
    },
    {
      _id: 15,
      nom: "La pauvreté",
      checked: false,
    },
    {
      _id: 16,
      nom: "Énergie renouvelable",
      checked: false,
    },
    {
      _id: 17,
      nom: "Développement rural",
      checked: false,
    },
    {
      _id: 18,
      nom: "Le transport",
      checked: false,
    },
    {
      _id: 19,
      nom: "La gestion des déchets",
      checked: false,
    },
    {
      _id: 20,
      nom: "Eau",
      checked: false,
    },
    {
      _id: 21,
      nom: "Jeunesse",
      checked: false,
    },
    {
      _id: 21,
      nom: "Droit",
      checked: false,
    },
    {
      _id: 22,
      nom: "Autre",
      checked: false,
    },
  ],
  instruments: [
    {
      _id: 1,
      libelle: "Tout",
      checked: false,
    },
    {
      _id: 2,
      libelle: "Assistance technique",
      checked: false,
    },
    {
      _id: 3,
      libelle: "Conseil et intermédiation",
      checked: false,
    },
    {
      _id: 4,
      libelle: "Participation au capital",
      checked: false,
    },
    {
      _id: 5,
      libelle: "Consultance",
      checked: false,
    },
    {
      _id: 6,
      libelle: "Prêt commercial",
      checked: false,
    },
    {
      _id: 7,
      libelle: "Prêt concessionnel",
      checked: false,
    },
    {
      _id: 8,
      libelle: "Don",
      checked: false,
    },
    {
      _id: 9,
      libelle: "Assurance",
      checked: false,
    },
    {
      _id: 10,
      libelle: "Garantie",
      checked: false,
    },
    {
      _id: 11,
      libelle: "Subventions",
      checked: false,
    },
    {
      _id: 11,
      libelle: "Subventions conditionnelles",
      checked: false,
    },
    {
      _id: 13,
      libelle: "Contributions en nature",
      checked: false,
    },
    {
      _id: 14,
      libelle: "Financement axé sur les résultats",
      checked: false,
    },
    {
      _id: 15,
      libelle: "Prêts au taux du marché",
      checked: false,
    },
    {
      _id: 16,
      libelle: "fonds propres",
      checked: false,
    },
    {
      _id: 17,
      libelle: "Actions",
      checked: false,
    },
    {
      _id: 18,
      libelle: "Autre",
      checked: false,
    },
  ],
  organisations: [
    {
      _id: 1,
      nom: "Tout",
      checked: false,
    },
    {
      _id: 2,
      nom: "Banque commerciale",
      checked: false,
    },
    {
      _id: 3,
      nom: "Banque multilatérale",
      checked: false,
    },
    {
      _id: 4,
      nom: "Organisation multilatérale",
      checked: false,
    },
    {
      _id: 5,
      nom: "Organisation gouvernementale",
      checked: false,
    },
    {
      _id: 6,
      nom: "Agence ou banque de développement bilatérale",
      checked: false,
    },
    {
      _id: 7,
      nom: "Société d'assurance ou de garanties",
      checked: false,
    },
    {
      _id: 8,
      nom: "Fonds d'investissement",
      checked: false,
    },
    {
      _id: 9,
      nom: "Autre",
      checked: false,
    },
  ],
  nda: [],
  entitesAccredite: [],
};
