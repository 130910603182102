/* =======================================================================================================
  File Name: moduleSliderAction.js
  Description: Slider Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  getSliders({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/sliders")
        .then((response) => {
          commit("SET_SLIDERS", response.data.sliders);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getActiveSlide({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/sliders/active")
        .then((response) => {
          commit("SET_ACTIVE_SLIDERS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  homeSlider({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/sliders/home")
        .then((response) => {
          commit("SET_ACTIVE_SLIDERS", response.data.slider);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createSlider({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/sliders", { payload })
        .then((response) => {
          commit("ADD_SLIDER", response.data.slider);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  activeSliderForHome({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/sliders/active", { payload })
        .then((response) => {
          commit("UPDATE_SLIDER", response.data.slider);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSlider({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/sliders", { payload })
        .then((response) => {
          commit("UPDATE_SLIDER", response.data.slider);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteSlider({ commit }, id) {
    const payload = { id: id };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/sliders/softdelete", { payload })
        .then((response) => {
          commit("DELETE_SLIDER", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  archiveSlider({ commit }, id) {
    const payload = { id: id };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/sliders/archive", { payload })
        .then((response) => {
          commit("DELETE_SLIDER", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
