export default {
  SET_EVENTS(state, data) {
    state.events = data;
  },
  SET_UNVALIDE_EVENTS(state, data) {
    state.unvalideEvents = data;
  },
  UPDATE_UNVALIDE_EVENT(state, data) {
    const index =
      state.unvalideEvents.events.length > 0
        ? state.unvalideEvents.events.findIndex((e) => e._id === data._id)
        : null;
    console.log(index);
    Object.assign(state.unvalideEvents.events[index], data);
  },
  SET_USER_AUTH_EVENTS(state, data) {
    state.userAuthEvents = data;
  },
  ADD_EVENT(state, data) {
    state.events.events.unshift(data);
  },
  ADD_USER_AUTH_EVENTS(state, data) {
    state.userAuthEvents.events.unshift(data);
  },
  UPDATE_EVENT(state, data) {
    const index =
      state.userAuthEvents.events.length > 0
        ? state.userAuthEvents.events.findIndex((e) => e._id === data._id)
        : null;
    Object.assign(state.userAuthEvents.events[index], data);
  },
  UPDATE_USER_AUTH_EVENT(state, data) {
    const index =
      state.userAuthEvents.events.length > 0
        ? state.userAuthEvents.events.findIndex((e) => e._id === data._id)
        : null;
    Object.assign(state.userAuthEvents.events[index], data);
  },
  DELETE_EVENT(state, id) {
    const index =
      state.events.events.length > 0
        ? state.events.events.findIndex((e) => e._id === id)
        : null;
    state.events.events.splice(index, 1);
  },
  DELETE_USER_AUTH_EVENT(state, id) {
    const index =
      state.userAuthEvents.events.length > 0
        ? state.userAuthEvents.events.findIndex((e) => e._id === id)
        : null;
    console.log(index);
    state.userAuthEvents.events.splice(index, 1);
  },
  SET_EVENTS_CATEGORIE(state, data) {
    state.categories = data.reverse();
  },
  ADD_EVENT_CATEGORIE(state, data) {
    state.categories.unshift(data);
  },
  UPDATE_EVENT_CATEGORIE(state, data) {
    console.log("data:", data);
    const index = state.categories.findIndex((e) => e._id === data._id);
    console.log(index);
    Object.assign(state.categories[index], data);
  },
  DELETE_EVENT_CATEGORIE(state, id) {
    const index = state.categories.findIndex((e) => e._id === id);
    state.categories.splice(index, 1);
  },
};
