/* =======================================================================================================
  File Name: moduleContact.js
  Description: Contact Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */
import state from "./moduleContactState.js";
import mutations from "./moduleContactMutations";
import actions from "./moduleContactActions.js";
import getters from "./moduleContactGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
