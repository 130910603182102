export default {
  SET_TYPES_DOCUMENT(state, data) {
    state.typeDocuments = data;
  },
  ADD_TYPE_DOCUMENT(state, data) {
    state.typeDocuments.unshift(data);
  },
  UPDATE_TYPE_DOCUMENT(state, data) {
    const index = state.typeDocuments.findIndex((t) => t._id === data._id);
    Object.assign(state.typeDocuments[index], data);
  },
  DELETE_TYPE_DOCUMENT(state, data) {
    const index = state.typeDocuments.findIndex((t) => t._id === data.id);
    state.typeDocuments.splice(index, 1);
  },
  SET_THEMATIQUE_DOCUMENT(state, data) {
    state.thematiqueDocuments = data;
  },
  ADD_THEMATIQUE_DOCUMENT(state, data) {
    state.thematiqueDocuments.unshift(data);
  },
  UPDATE_THEMATIQUE_DOCUMENT(state, data) {
    const index = state.thematiqueDocuments.findIndex(
      (t) => t._id === data._id
    );
    Object.assign(state.thematiqueDocuments[index], data);
  },
  DELETE_THEMATIQUE_DOCUMENT(state, data) {
    const index = state.thematiqueDocuments.findIndex((t) => t._id === data.id);
    state.thematiqueDocuments.splice(index, 1);
  },
  SET_LAST_FIVE_PUBLICATION_DOCUMENT(state, data) {
    state.lastFivePublication = data;
  },
  SET_PUBLICATION_DOCUMENT(state, data) {
    state.publications = data;
  },
  ADD_PUBLICATION_DOCUMENT(state, data) {
    state.publications.unshift(data);
  },
  ADD_DOCUMENT(state, data) {
    state.documents.documents.unshift(data);
  },
  UPDATE_DOCUMENT(state, data) {
    const index = state.documents.documents.findIndex(
      (p) => p._id === data._id
    );
    Object.assign(state.documents.documents[index], data);
  },
  DELETE_DOCUMENT(state, data) {
    const index = state.documents.documents.findIndex((p) => p._id === data.id);
    state.documents.documents.splice(index, 1);
  },
  UPDATE_PUBLICATION_DOCUMENT(state, data) {
    const index = state.publications.findIndex((p) => p._id === data._id);
    Object.assign(state.publications[index], data);
  },
  DELETE_PUBLICATION_DOCUMENT(state, data) {
    const index = state.publications.findIndex((p) => p._id === data.id);
    state.publications.splice(index, 1);
  },
  SET_DOSSIER_PAYS_DOCUMENT(state, data) {
    state.dossier_pays = data;
  },
  SET_DOSSIER_PAYS_DOCUMENT_PUBLIC(state, data) {
    state.dossier_pays_public = data;
  },
  ADD_DOSSIER_PAYS_DOCUMENT(state, data) {
    state.dossier_pays.unshift(data);
  },
  UPDATE_DOSSIER_PAYS_DOCUMENT(state, data) {
    const index = state.dossier_pays.findIndex((d) => d._id === data._id);
    Object.assign(state.dossier_pays[index], data);
  },
  DELETE_DOSSIER_PAYS_DOCUMENT(state, data) {
    const index = state.dossier_pays.findIndex((d) => d._id === data.id);
    state.dossier_pays.splice(index, 1);
  },
  SET_DOCUMENTS(state, data) {
    state.documents = data;
  },
};
