/* =======================================================================================================
  File Name: moduleForum.js
  Description: Forum Module
  --------------------------------------------------------------------------------------------------------
======================================================================================================= */

import state from "./moduleForumState.js";
import mutations from "./moduleForumMutations.js";
import actions from "./moduleForumActions.js";
import getters from "./moduleForumGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
