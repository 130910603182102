/* =======================================================================================================
  File Name: StateForum.js
  Description: Forum State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  canaux: [],
  discussions: [],
  adminDiscussions: [],
};
