/* =======================================================================================================
  File Name: moduleProjetAction.js
  Description: Projet Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  getProjets({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projets/${page}/${limit}`)
        .then((response) => {
          commit("SET_PROJETS", response.data.projets);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProjetsByPaysId({ commit }, id) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projets/pays/all/${id}`, config)
        .then((response) => {
          commit("SET_PROJETS_PAYS", response.data.projets);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProjetsByEa(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/projets/ea", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProjetsByMecanisme(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/projets/mecanisme", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createProjet({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/projets", { payload }, config)
        .then((response) => {
          commit("ADD_PROJET", response.data.projet);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProjet({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/projets", { payload }, config)
        .then((response) => {
          commit("UPDATE_PROJET", response.data.projet);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  validateProjet({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/projets/validate", { payload }, config)
        .then((response) => {
          commit("UPDATE_PROJET", response.data.projet);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteProjet({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/projets/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_PROJET", response.data.projet);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
