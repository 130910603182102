/* =======================================================================================================
  File Name: StateNouvelle.js
  Description: Nouvelle State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  typeDocuments: [],
  thematiqueDocuments: [],
  publications: [],
  lastFivePublication: [],
  dossier_pays: [],
  dossier_pays_public: [],
  documents: [],
};
