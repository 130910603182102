/* =======================================================================================================
  File Name: moduleNouvelleAction.js
  Description: Nouvelle Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  getNouvellesPublier({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/nouvelles/publier/${page}/${limit}`)
        .then((response) => {
          commit("SET_NOUVELLES_PUBLIER", response.data.nouvelles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNouvellesPublierByPaysId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/nouvelles/pays/publier/${id}`)
        .then((response) => {
          commit("SET_NOUVELLES_PAYS", response.data.nouvelles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNouvellesByEa({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/nouvelles/entite/accredite/publier/${id}`)
        .then((response) => {
          commit("SET_NOUVELLES_PAYS", response.data.nouvelles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNouvelles({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/nouvelles")
        .then((response) => {
          commit("SET_NOUVELLES", response.data.nouvelles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNouvellesById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/nouvelles/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createNouvelle({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/nouvelles", { payload }, config)
        .then((response) => {
          commit("ADD_NOUVELLE", response.data.nouvelle);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateNouvelle({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/nouvelles", { payload }, config)
        .then((response) => {
          commit("UPDATE_NOUVELLE", response.data.nouvelle);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  publieNouvelle({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/nouvelles/publier", { payload }, config)
        .then((response) => {
          commit("UPDATE_NOUVELLE", response.data.nouvelle);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteNouvelle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/nouvelle/${id}`)
        .then((response) => {
          commit("DELETE_NOUVELLE", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPublications({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/publications")
        .then((response) => {
          commit("SET_PUBLICATIONS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPublicationsById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/publications/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPublication({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/publications", { payload })
        .then((response) => {
          commit("ADD_PUBLICATION", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePublication({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/publications", { payload })
        .then((response) => {
          commit("UPDATE_PUBLICATION", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePublication({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/publication/${id}`)
        .then((response) => {
          commit("DELETE_PUBLICATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
