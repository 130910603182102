/* =======================================================================================================
  File Name: moduleOrganisationAction.js
  Description: Organisation Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  addPointContactToOrganisation(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations/add/organisation/contact", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePointContactPrincipal(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/api/organisations/update/organisation/contact",
          { payload },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  valideOrganisationContact(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations/valided/contact", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removePointContact(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/api/organisations/remove/organisation/contact",
          { payload },
          config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchOrganisationType(context, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/types-organisation/search/${query}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchOrganisation(context, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/search/organisation/${query}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrganisationName(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/name/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrganisationLogoUrl(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/organisations/logo`, { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrganisations({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/${page}/${limit}`)
        .then((response) => {
          commit("SET_ORGANISATIONS", response.data.organisations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrganisationById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrganisationByUserId({ commit }, id) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/auth/user/organisation/${id}`, config)
        .then((response) => {
          commit("SET_AUTH_USER_ORGANISATIONS", response.data.organisations);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createOrganisationInProduitComponent(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/organisations", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/organisations", { payload }, config)
        .then((response) => {
          commit("ADD_ORGANISATION", response.data.organisation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations", { payload }, config)
        .then((response) => {
          commit("UPDATE_ORGANISATION", response.data.organisation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateLogoOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations/add/organisation/logo", { payload }, config)
        .then((response) => {
          commit("UPDATE_AUTH_USER_ORGANISATION", response.data.organisation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateBannerOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations/add/organisation/banner", { payload }, config)
        .then((response) => {
          commit("UPDATE_AUTH_USER_ORGANISATION", response.data.organisation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAuthUserOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations", { payload }, config)
        .then((response) => {
          commit("UPDATE_AUTH_USER_ORGANISATION", response.data.organisation);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteOrganisation({ commit }, payload) {
    const { id } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_ORGANISATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAuthUserOrganisation({ commit }, payload) {
    const { id } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/organisations/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_AUTH_USER_ORGANISATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrganisationsFournisseurProduitFinancier({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organisations/fournisseur/produits-finance`)
        .then((response) => {
          commit("SET_ORGANISATIONS_FOURNISSEURS", response.data.fournisseurs);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchOrganisationFournisseurProduitFinancier() {},
  getTypeOrganisations({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/types-organisation/${page}/${limit}`)
        .then((response) => {
          commit("SET_TYPE_ORGANISATION", response.data.types);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTypeOrganisationById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/types-organisation/${id}`)
        .then((response) => {
          console.log(response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTypeOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/types-organisation", { payload }, config)
        .then((response) => {
          commit("ADD_TYPE_ORGANISATION", response.data.type);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTypeOrganisation({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/types-organisation", { payload }, config)
        .then((response) => {
          commit("UPDATE_TYPE_ORGANISATION", response.data.type);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTypeOrganisation({ commit }, id) {
    const payload = { id: id };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/types-organisation/delete", { payload }, config)
        .then((response) => {
          commit("DELETE_TYPE_ORGANISATION", id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
