/* =======================================================================================================
  File Name: StateParametre.js
  Description: Parametre State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  publiquesCibles: [],
  domaines: [],
};
