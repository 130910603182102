/* =======================================================================================================
  File Name: moduleForumAction.js
  Description: Forum Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  getCanaux({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/canal-discussion")
        .then((response) => {
          commit("SET_CANAUX", response.data.canaux);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCanal({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/canal-discussion", { payload }, config)
        .then((response) => {
          commit("ADD_CANAL", response.data.canal);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCanal({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/canal-discussion", { payload }, config)
        .then((response) => {
          commit("UPDATE_CANAL", response.data.canal);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCanal({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/canal-discussion/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_CANAL", response.data.canal);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getForumDiscussionsForAdmin({ commit }, payload) {
    const { page, limit } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/forum/admin/discussions/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_ADMIN_DISCUSSION", response.data.discussions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  suspendreDiscussion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/forum/suspendre`, { payload }, config)
        .then((response) => {
          commit("UPDATE_ADMIN_DISCUSSION", response.data.discussion);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  activeDiscussion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/forum/active`, { payload }, config)
        .then((response) => {
          commit("UPDATE_ADMIN_DISCUSSION", response.data.discussion);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAdminDiscussion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/forum/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_ADMIN_DISCUSSION", response.data.discussion);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getForumDiscussions({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/forum/discussions/${page}/${limit}`)
        .then((response) => {
          commit("SET_FORUM_QUESTION", response.data.discussions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getForumDiscussionByUserId({ commit }, payload) {
    const { userId, limitPerPageDiscussion, currentPageDiscussion } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/forum/user/discussions/${currentPageDiscussion}/${limitPerPageDiscussion}/${userId}`
        )
        .then((response) => {
          commit("SET_FORUM_QUESTION", response.data.discussions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getForumDiscussionBySlug(context, slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/forum/discussion/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  commentNiveauUn(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/forum/discussion/comment-niveau-un", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  commentNiveauDeux(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/forum/discussion/comment-niveau-deux", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  increaseDiscussionVue(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/forum/discussion/increase-vue", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createForumDiscussion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/forum/discussion", { payload }, config)
        .then((response) => {
          commit("ADD_FORUM_QUESTION", response.data.discussion);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateForumDiscussion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/forum/discussion", { payload }, config)
        .then((response) => {
          commit("UPDATE_FORUM_QUESTION", response.data.discussion);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteForumDiscussion({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/forum/discussion/softdelete", { payload }, config)
        .then((response) => {
          commit("DELETE_FORUM_QUESTION", response.data.discussion);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
