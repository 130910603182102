/* =======================================================================================================
  File Name: moduleUserAction.js
  Description: User Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";

export default {
  getUserById(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/single/user/${id}`)
        .then((response) => {
          console.log(response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  publicUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/users/public")
        .then((response) => {
          commit("SET_PUBLIC_USERS", response.data.users);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUsers({ commit }, payload) {
    const { page, limit } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    // console.log("config", config);
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_USERS", response.data.users);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getExperts({ commit }, payload) {
    const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/experts/${page}/${limit}`)
        .then((response) => {
          commit("SET_EXPERT", response.data.users);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getdemandeCertificationUsers({ commit }, payload) {
    const { page, limit } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/demande-certification/${page}/${limit}`, config)
        .then((response) => {
          commit("SET_DEMANDE_EXPERT", response.data.users);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  validateUserAccount({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/validate", { payload }, config)
        .then((response) => {
          commit("UPDATE_USER", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  valideDemandeCertificationCompteExpertiseFrancophone({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/api/users/validation-certification-compte-expert",
          { payload },
          config
        )
        .then((response) => {
          commit("REMOVE_DEMANDE_EXPERT", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  demandeCertificationCompteExpertFrancophone({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/api/users/demande-certification-compte-expert",
          { payload },
          config
        )
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateThematiqueExpert({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/update-thematiques", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addReference({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-reference", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateReference({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/update-reference", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeReference({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-reference", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  register(context, payload) {
    console.log("payload:", payload);
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/register", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyEmail(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/verify-email", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/login", { payload })
        .then((response) => {
          //console.log(response.data.user);
          localStorage.setItem("Token", response.data.user.token);
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAuthUser({ commit }, payload) {
    console.log(payload);
    commit("SET_USER_AUTHENTICATION_INFO", payload);
  },
  update({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    // console.log("config", config);
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/update", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateProfile({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    // console.log("config", config);
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/update/profile", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  profilImage({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    // console.log("config", config);
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/update/profil/image", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendResetPasswordTokenOnEmail(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/send-reset-password", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword(context, payload) {
    const { token } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/reset-password", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRole({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    // console.log("config", config);
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/role", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveUserPrivilege(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    // console.log("config", config);
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/privilege", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addBanner({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/banner", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addEmailAdresse({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-email-adresse", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeEmailAdresse({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    const { email } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/remove-email-adresse", { payload }, config)
        .then((response) => {
          commit("REMOVE_USER_OTHER_EMAIL", email);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addUserLinkedin({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-linkedin-account", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeUserLinkedin(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/remove-linkedin-account", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addUserTwitter({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-twitter-account", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeUserTwitter(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/remove-twitter-account", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addUserFacebook({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-facebook-account", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeUserFacebook(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/remove-facebook-account", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addUserSiteWeb({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-site-web", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeUserSiteWeb(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/remove-site-web", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addUserPhoneContact({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/add-phone", { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeCV({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/cv-delete", { payload }, config)
        .then((response) => {
          console.log(response.data.user);
          commit("REMOVE_AUTH_USER_CV", payload.cv);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeUserPhoneContact(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put("/api/users/remove-phone", { payload }, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAuthUserInfo({ commit }, payload) {
    const { id } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/auth/info/${id}`, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateRechercheFilter({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/users/update-filtre`, { payload }, config)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_INFO", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addFollowing(context, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/api/following", { payload }, config)
        .then((response) => {
          // commit("UPDATE_USER", response.data.user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getLastFourFollowing(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/following/last-four/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchUsersFollowing(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/following/search-user`, {
          payload,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchExperts(context, value) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/search/expert/${value}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  filterExpert(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/users/expert-filter`, { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFollowingByUserFollowId({ commit }, payload) {
    const { page, limit, userFollowId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/following/${page}/${limit}/${userFollowId}`)
        .then((response) => {
          commit("SET_USER_AUTHENTICATION_FOLLOWING", response.data.followings);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  countFollowing(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/following/count/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkIfUserIsFollowing(context, payload) {
    // console.log(payload);
    const { userFollowId, userFollowingId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/following/check/${userFollowId}/${userFollowingId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unFollowing(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/following/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  expertsSuivi({ commit }, data) {
    commit("SET_EXPERTS_SUIVI", data);
  },
  updateExpertsSuivi({ commit }, data) {
    commit("UPDATE_EXPERT_SUIVI", data);
  },
  addListChat({ commit }, user) {
    commit("ADD_LIST_CHAT", user);
  },
  historyMessages({ commit }, conversations) {
    commit("HISTORY_MESSAGE", conversations);
  },
  updateConversationHistory({ commit }, payload) {
    commit("UPDATE_CONVERSATION_HISTORY", payload);
  },
  updateMessageListChat({ commit }, payload) {
    commit("UPDATE_MESSAGE_WHEN_LIST_CHAT_IS_OPEN", payload);
  },
  updateNewMessage({ commit }, message) {
    commit("UPDATE_MESSAGE_EXPERT_SUIVI", message);
  },
  updateNewConvesationMessage({ commit }, message) {
    commit("UPDATE_CONVERSATION", message);
  },
  expertsSearch({ commit }, data) {
    commit("SET_EXPERTS_SEARCH", data);
  },
  setMessageOnWrite({ commit }, onWriteMessage) {
    commit("MESSAGE_ON_WRITE", onWriteMessage);
  },
  removeMessageOnWrite({ commit }, index) {
    commit("REMOVE_MESSAGE_ON_WRITE", index);
  },
  resetExpertsSearch({ commit }) {
    commit("RESET_EXPERT_SEARCH");
  },
  addNewConversationIfNoExist({ commit }, payload) {
    commit("ADD_NEW_CONVERSATION_IF_NO_EXIST", payload);
  },
  //init existing auth user conversations
  initExistingAuthUserConversations({ commit }, payload) {
    commit("INIT_EXISTING_AUTH_USER_CONVERSATION", payload);
  },
  setConversationsHistory({ commit }, conversations) {
    commit("SET_CONVERSATIONS_HISTORY", conversations);
  },
};
