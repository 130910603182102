export default {
  ADD_PRODUIT_IN_COMPARATEUR(state, produit) {
    console.log("produit:", produit);
    if (state.comparateur.length < 3) {
      if (produit.checked === true) {
        const prod = state.comparateur.find((p) => {
          return p._id.includes(produit._id);
        });
        if (prod) {
          const index =
            state.comparateur.length > 0
              ? state.comparateur.findIndex((p) => p._id === produit._id)
              : null;
          state.comparateur.splice(index, 1);
        } else {
          state.comparateur.push(produit);
        }
      } else {
        if (state.comparateur.length > 0) {
          const index = state.comparateur.findIndex(
            (p) => p._id === produit._id
          );
          state.comparateur.splice(index, 1);
        }
      }
    } else {
      const index = state.comparateur.findIndex((p) => p._id === produit._id);
      state.comparateur.splice(index, 1);
    }
  },
  RESET_PRODUIT_IN_COMPARATEUR(state) {
    state.comparateur = [];
  },
  RESULT_AUTODIAGNOSTIC(state, data) {
    state.results = data;
  },
  SET_PRODUITS(state, data) {
    state.produits = data;
  },
  SET_TYPE_PRODUITS(state, data) {
    state.typeProduits = data;
  },
  ADD_PRODUITS(state, data) {
    state.produits.produits.unshift(data);
  },
  ADD_TYPE_PRODUITS(state, data) {
    state.typeProduits.unshift(data);
  },
  UPDATE_PRODUIT(state, data) {
    const index = state.produits.produits.findIndex((p) => p._id === data._id);
    Object.assign(state.produits.produits[index], data);
  },
  UPDATE_TYPE_PRODUIT(state, data) {
    const index = state.typeProduits.findIndex((p) => p._id === data._id);
    Object.assign(state.typeProduits[index], data);
  },
  DELETE_PRODUIT(state, id) {
    const index = state.produits.produits.findIndex((p) => p._id === id);
    state.produits.produits.splice(index, 1);
  },
  DELETE_TYPE_PRODUIT(state, id) {
    const index = state.typeProduits.findIndex((p) => p._id === id);
    state.typeProduits.splice(index, 1);
  },
  ORGANISATION_CHECK_ALL(state) {
    state.organisations.forEach((organisation, index) => {
      organisation.checked = true;
      Object.assign(state.organisations[index], organisation);
    });
  },
  ORGANISATION_DESELECTED_ALLL(state) {
    state.organisations.forEach((organisation, index) => {
      organisation.checked = false;
      Object.assign(state.organisations[index], organisation);
    });
  },
  SECTEUR_CHECK_ALL(state) {
    state.secteurs.forEach((secteur, index) => {
      secteur.checked = true;
      Object.assign(state.secteurs[index], secteur);
    });
  },
  SECTEUR_DESELECTED_ALLL(state) {
    state.secteurs.forEach((secteur, index) => {
      secteur.checked = false;
      Object.assign(state.secteurs[index], secteur);
    });
  },
  SET_INSTRUMENT(state, data) {
    state.instruments = data;
  },
  ADD_INSTRUMENT(state, data) {
    state.instruments.unshift(data);
  },
  UPDATE_INSTRUMENT(state, data) {
    console.log(data);
    const index = state.instruments.findIndex((i) => i._id === data._id);
    Object.assign(state.instruments[index], data);
  },
  DELETE_INSTRUMENT(state, data) {
    const index = state.instruments.findIndex((i) => i._id === data._id);
    state.instruments.splice(index, 1);
  },
  SET_MECANISME(state, data) {
    state.mecanismes = data;
  },
  ADD_MECANISME(state, data) {
    state.mecanismes.unshift(data);
  },
  UPDATE_MECANISME(state, data) {
    const index = state.mecanismes.findIndex((i) => i._id === data._id);
    Object.assign(state.mecanismes[index], data);
  },
  DELETE_MECANISME(state, data) {
    const index = state.mecanismes.findIndex((i) => i._id === data._id);
    state.mecanismes.splice(index, 1);
  },
  SET_NDA(state, data) {
    state.nda = data;
  },
  ADD_NDA(state, data) {
    state.nda.data.unshift(data);
  },
  UPDATE_NDA(state, data) {
    const index = state.nda.data.findIndex((i) => i._id === data._id);
    Object.assign(state.nda.data[index], data);
  },
  DELETE_NDA(state, data) {
    const index = state.nda.data.findIndex((i) => i._id === data._id);
    state.nda.data.splice(index, 1);
  },
  SET_ENTITES_ACCREDITE(state, data) {
    state.entitesAccredite = data;
  },
  ADD_ENTITES_ACCREDITE(state, data) {
    state.entitesAccredite.data.unshift(data);
  },
  UPDATE_ENTITES_ACCREDITE(state, data) {
    const index = state.entitesAccredite.data.findIndex(
      (i) => i._id === data._id
    );
    Object.assign(state.entitesAccredite.data[index], data);
  },
  DELETE_ENTITES_ACREDITE(state, data) {
    const index = state.entitesAccredite.data.findIndex(
      (i) => i._id === data._id
    );
    state.entitesAccredite.data.splice(index, 1);
  },
  INSTRUMENT_CHECK_ALL(state) {
    state.instruments.forEach((intrument, index) => {
      intrument.checked = true;
      Object.assign(state.instruments[index], intrument);
    });
  },
  INSTRUMENT_DESELECTED_ALLL(state) {
    state.instruments.forEach((intrument, index) => {
      intrument.checked = false;
      Object.assign(state.instruments[index], intrument);
    });
  },
};
