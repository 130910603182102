import socket from "@/socket.js";
export default {
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_PUBLIC_USERS(state, data) {
    state.public_users = data.reverse();
  },
  UPDATE_USER(state, data) {
    const index = state.users.users.findIndex((u) => u._id === data._id);
    console.log(index);
    if (index) {
      Object.assign(state.users.users[index], data);
    }
  },
  SET_USER_AUTHENTICATION_FOLLOWING(state, data) {
    state.auth_user_followings = data;
  },
  SET_USER_AUTHENTICATION_INFO(state, payload) {
    // Store data in localStorage
    if (payload) {
      state.userInfo = payload;
      const infos = {
        _id: payload._id,
        validation_admin: payload.validation_admin,
        token: payload.token,
        site: payload.site,
        sexe: payload.sexe,
        role: payload.role,
        rechercheInformFiltre: payload.rechercheInformFiltre,
        rechercheFitreOrganisation: payload.rechercheFitreOrganisation,
        rechercheFiltreTag: payload.rechercheFiltreTag,
        rechercheFiltreProduit: payload.rechercheFiltreProduit,
        rechercheFiltrePays: payload.rechercheFiltrePays,
        rechercheFiltreFormation: payload.rechercheFiltreFormation,
        profile_public: payload.profile_public,
        profile_barner: payload.profile_barner,
        prenoms: payload.prenoms,
        phones: payload.phones,
        phone: payload.phone,
        pays: payload.pays,
        organisation: payload.organisation,
        nom_pays: payload.nom_pays,
        nom_organisation: payload.nom_organisation,
        nom: payload.nom,
        isProfileCompleted: payload.isProfileCompleted,
        fonction_organisation: payload.fonction_organisation,
        email: payload.email,
        commentaire: payload.commentaire,
        avatar: payload.avatar,
        autreAdresseEmail: payload.autreAdresseEmail,
        actif: payload.actif,
        acronyme_organisation: payload.acronyme_organisation,
        demande_expert: payload.demande_expert,
        date_demande_certification_compte_expert:
          payload.date_demande_certification_compte_expert,
        expert_francophone: payload.expert_francophone,
        date_certification_compte_expert:
          payload.date_certification_compte_expert,
        cv: payload.cv,
        description_expertise: payload.description_expertise,
        type_expertise: payload.type_expertise,
        references: payload.references,
      };
      localStorage.removeItem("userInfo");
      localStorage.setItem("userInfo", JSON.stringify(infos));
    }
  },
  REMOVE_AUTH_USER_CV(state, payload) {
    const index = state.userInfo.cv.findIndex((c) => c.url === payload.url);
    state.userInfo.cv.splice(index, 1);
  },
  REMOVE_USER_OTHER_EMAIL(state, email) {
    const index = state.userInfo.autreAdresseEmail
      ? state.userInfo.autreAdresseEmail.findIndex((e) => e === email)
      : null;
    if (index) {
      console.log(index);
      state.userInfo.autreAdresseEmail.splice(index, 1);
    }
  },
  SET_DEMANDE_EXPERT(state, data) {
    state.demandes_expert = data;
  },
  SET_EXPERT(state, data) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo && userInfo.expert_francophone) {
      const index = data.users.findIndex((u) => u._id === userInfo._id);
      data.users.splice(index, 1);
    }
    state.experts = data;
  },
  SET_EXPERTS_SUIVI(state, data) {
    state.experts_suivi = data;
  },
  UPDATE_EXPERT_SUIVI(state, data) {
    const index =
      state.experts_suivi.lenght > 0
        ? state.experts_suivi.findIndex((e) => e._id === data._id)
        : null;
    if (index) {
      Object.assign(state.experts_suivi[index], data);
    }
  },
  ADD_LIST_CHAT(state, user) {
    const index = state.listesChat.findIndex((l) => l._id === user._id);
    if (state.listesChat.length < 3) {
      if (!state.listesChat[index]) {
        user.hasNewMessages = false;
        state.listesChat.push(user);
      }
    }
  },
  UPDATE_MESSAGE_EXPERT_SUIVI(state, message) {
    const index = state.experts_suivi.findIndex((e) => e._id === message.from);
    const messages = state.experts_suivi[index].messages;
    messages.push(message);
    if (
      state.listesChat.find((u) => u._id === state.experts_suivi[index]._id)
    ) {
      const user = {
        _id: state.experts_suivi[index]._id,
        userID: state.experts_suivi[index]._id,
        avatar: state.experts_suivi[index].avatar,
        nom: state.experts_suivi[index].nom,
        nom_pays: state.experts_suivi[index].nom_pays,
        prenoms: state.experts_suivi[index].prenoms,
        fonction_organisation: state.experts_suivi[index].fonction_organisation,
        type_expertise: state.experts_suivi[index].type_expertise,
        hasNewMessages: false,
        messages: messages,
      };
      Object.assign(state.experts_suivi[index], user);
    } else {
      const user = {
        _id: state.experts_suivi[index]._id,
        userID: state.experts_suivi[index]._id,
        avatar: state.experts_suivi[index].avatar,
        nom: state.experts_suivi[index].nom,
        nom_pays: state.experts_suivi[index].nom_pays,
        prenoms: state.experts_suivi[index].prenoms,
        fonction_organisation: state.experts_suivi[index].fonction_organisation,
        type_expertise: state.experts_suivi[index].type_expertise,
        hasNewMessages: true,
        messages: messages,
      };
      Object.assign(state.experts_suivi[index], user);
    }
  },
  UPDATE_MESSAGE_WHEN_LIST_CHAT_IS_OPEN(state, payload) {
    const { content, index } = payload;
    state.listesChat[index].messages.push({
      content,
      createdAt: new Date(Date.now()).toISOString(),
      fromSelf: true,
    });
  },
  INIT_EXISTING_AUTH_USER_CONVERSATION(state, payload) {
    const { userId, conversations } = payload;
    const messagesPerUser = new Map();
    conversations.forEach((conversation) => {
      const { from, to } = conversation;
      const otherUser = userId === from ? to : from;
      if (messagesPerUser.has(otherUser)) {
        messagesPerUser.get(otherUser).push(conversation);
      } else {
        messagesPerUser.set(otherUser, [conversation]);
      }
    });
    //console.log("messagesPerUser: ", messagesPerUser);
    state.messagePerUser = messagesPerUser;
  },
  ADD_NEW_CONVERSATION_IF_NO_EXIST(state, conversation) {
    /** conversation structure */
    // _id:"6375dd5f741e57d2e2d5f21c"
    // avatar:"/images/1677339357694.jpeg"
    // fonction_organisation:"Chef projet chez Beyima tech"
    // hasNewMessages:false
    // messages:Array[2]
    // nom:"Djezou"
    // nom_pays:"Côte d’Ivoire"
    // prenoms:"Eric Martial"
    // type_expertise:"Nationale"
    // userID:"6375dd5f741e57d2e2d5f21c"
    state.conversationsPerUser.unshift(conversation);
  },
  SET_CONVERSATIONS_HISTORY(state, conversations) {
    state.conversationsPerUser = conversations;
  },
  UPDATE_CONVERSATION(state, message) {
    const index = state.conversationsPerUser.findIndex(
      (c) => c._id === message.from
    );
    const messages = state.conversationsPerUser[index].messages;
    messages.push(message);
    const user = {
      _id: state.conversationsPerUser[index]._id,
      userID: state.conversationsPerUser[index]._id,
      avatar: state.conversationsPerUser[index].avatar,
      nom: state.conversationsPerUser[index].nom,
      nom_pays: state.conversationsPerUser[index].nom_pays,
      prenoms: state.conversationsPerUser[index].prenoms,
      fonction_organisation:
        state.conversationsPerUser[index].fonction_organisation,
      type_expertise: state.conversationsPerUser[index].type_expertise,
      hasNewMessages: true,
      messages: messages,
    };
    Object.assign(state.conversationsPerUser[index], user);
  },
  UPDATE_CONVERSATION_HISTORY(state, payload) {
    const { content, _id } = payload;
    const index = state.conversationsPerUser.findIndex((c) => c._id === _id);
    state.conversationsPerUser[index].messages.push({
      content,
      createdAt: new Date(Date.now()).toISOString(),
      fromSelf: true,
    });
  },
  HISTORY_MESSAGE(state, conversations) {
    //console.log("conversations:", conversations);
    const index =
      state.experts_suivi.length > 0
        ? state.experts_suivi.findIndex(
            (user) => user._id === conversations.selectedUser
          )
        : null;
    //console.log(index);
    const indexSearch =
      state.searchExpertsResults.length > 0
        ? state.searchExpertsResults.findIndex(
            (user) => user._id === conversations.selectedUser
          )
        : null;
    conversations.conversations.forEach((message) => {
      if (!message.readed) {
        socket.emit("read message", {
          messageId: message._id,
          to: conversations.selectedUser,
        });
      }
      if (index || index === 0) {
        //console.log(index);
        state.experts_suivi[index].messages.push(message);
      }
      if (indexSearch || indexSearch === 0)
        state.searchExpertsResults[indexSearch].messages.push(message);
    });
  },
  SET_EXPERTS_SEARCH(state, data) {
    state.searchExpertsResults = data;
  },
  RESET_EXPERT_SEARCH(state) {
    state.searchExpertsResults = [];
  },
  MESSAGE_ON_WRITE(state, onWriteMessage) {
    //console.log(onWriteMessage.index);
    state.messagesOnWrite[onWriteMessage.index] = onWriteMessage;
  },
  REMOVE_MESSAGE_ON_WRITE(state, index) {
    state.messagesOnWrite.splice(index, 1);
  },
};
