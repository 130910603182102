/* =======================================================================================================
  File Name: StateSlider.js
  Description: Slider State
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */

export default {
  homeSliders: [
    {
      _id: 1,
      url: "https://cop27.eg/assets/img/banners/sharam.jpg",
      description:
        "La 27e Conférence des Nations Unies sur le Climat (CdP27) Sharm El Sheikh, du 6 au 18 novembre 2022",
      type: "Évenement",
      timer: new Date("2022-11-06T08:00:00"),
      titre: null,
      route: "events/1",
    },
    {
      _id: 2,
      url: "https://cop27.eg/assets/img/banners/use/5.jpg",
      description: null,
      type: null,
      timer: null,
      titre: null,
      route: null,
    },
    {
      _id: 3,
      url: "https://cop27.eg/assets/img/banners/use/3.jpg",
      description: null,
      type: null,
      timer: null,
      titre: null,
      route: null,
    },
    {
      _id: 4,
      url: "https://cop27.eg/assets/img/banners/use/the-capital-01.jpg",
      description: null,
      type: null,
      timer: null,
      titre: null,
      route: null,
    },
  ],
  sliders: [
    {
      _id: 1,
      titre: "Slider home page",
      active: true,
      slides: [
        {
          _id: 1,
          url: "https://cop27.eg/assets/img/banners/sharam.jpg",
          description:
            "La 27e Conférence des Nations Unies sur le Climat (CdP27) Sharm El Sheikh, du 6 au 18 novembre 2022",
          type: "Évenement",
          timer: new Date("2022-11-06T08:00:00"),
          titre: null,
          route: "events/1",
        },
        {
          _id: 2,
          url: "https://cop27.eg/assets/img/banners/use/5.jpg",
          description: null,
          type: null,
          timer: null,
          titre: null,
          route: null,
        },
        {
          _id: 3,
          url: "https://cop27.eg/assets/img/banners/use/3.jpg",
          description: null,
          type: null,
          timer: null,
          titre: null,
          route: null,
        },
        {
          _id: 4,
          url: "https://cop27.eg/assets/img/banners/use/the-capital-01.jpg",
          description: null,
          type: null,
          timer: null,
          titre: null,
          route: null,
        },
      ],
    },
  ],
};
