/* =======================================================================================================
  File Name: moduleParametreAction.js
  Description: Parametre Action
  --------------------------------------------------------------------------------------------------------
  Auteur: Djezou
  ======================================================================================================= */
import axios from "@/axios.js";
export default {
  getPubliquesCibles({ commit }) {
    // const { page, limit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/publiques`)
        .then((response) => {
          commit("SET_PUBLIQUES_CIBLES", response.data.publiques);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPubliqueCilble({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/publiques`, { payload }, config)
        .then((response) => {
          commit("ADD_PUBLIQUE_CIBLE", response.data.publique);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePubliqueClible({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/publiques`, { payload }, config)
        .then((response) => {
          commit("UPDATE_PUBLIQUE_CIBLE", response.data.publique);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePubliqueClible({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/publiques/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_PUBLIQUE_CIBLE", response.data.publique);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDomaines({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/domaines`)
        .then((response) => {
          commit("SET_DOMAINES", response.data.domaines);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createDomaine({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/domaines`, { payload }, config)
        .then((response) => {
          commit("ADD_DOMAINE", response.data.domaine);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateDomaine({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/domaines`, { payload }, config)
        .then((response) => {
          commit("UPDATE_DOMAINE", response.data.domaine);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDomaine({ commit }, payload) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/domaines/delete`, { payload }, config)
        .then((response) => {
          commit("DELETE_DOMAINE", response.data.domaine);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
